import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { hasError } from 'helpers/utils'
import { I18n } from 'shared'
import classNames from 'classnames'

const TextInputField = ({
  defaultValue,
  label,
  autoComplete,
  inputRef,
  required,
  clearable,
  disabled,
  placeholder,
  name,
  wrapperClass,
  inputClass,
  serverErrors,
  modalTarget,
  modalTargetLabel
}) => {
  const [currentValue, setCurrentValue] = useState(defaultValue)

  useEffect(() => {
    inputRef.current = currentValue
  }, [inputRef, currentValue])

  const errorKeys = Object.keys(serverErrors)
  const errorMessage = `${label} ${serverErrors[name]}`
  const invalid = hasError(name, errorKeys)

  return (
    <div
      className={classNames(`form-group ${wrapperClass}`, {
        clearable,
        'form-group-invalid': invalid
      })}
    >
      <div className="d-flex justify-content-between align-items-end">
        {label && (
          <label className="form-control-label" htmlFor={name}>
            {label}
            {required && <abbr title={I18n.t('common.required')}>*</abbr>}
          </label>
        )}

        {modalTarget && (
          <a
            href="#"
            className="text modal-label-hint p-2 pb-0 mb-n1 gray-600"
            data-toggle="modal"
            data-target={modalTarget}
          >
            {modalTargetLabel ? (
              <span>{modalTargetLabel}</span>
            ) : (
              <i className={'fal fa-info-circle fa-fw cursor'} />
            )}
          </a>
        )}
      </div>

      <div className="position-relative d-flex">
        <input
          className={classNames(`form-control translated-field ${inputClass}`, {
            required,
            'is-invalid': invalid
          })}
          type="text"
          autoComplete={autoComplete}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          value={currentValue}
          onChange={({ target: { value } }) => {
            setCurrentValue(value || '')
          }}
          required
        />

        {currentValue.length > 0 && (
          <i
            className={
              'fal fa-times fa-sm fa-fw reset-input align-self-center ml-2 cursor'
            }
            onClick={(e) => {
              e.preventDefault()
              setCurrentValue('')
            }}
          />
        )}
      </div>
      {invalid && errorMessage && (
        <small className="form-text text-danger text-sm form-control-inline-error">
          {errorMessage}
        </small>
      )}
    </div>
  )
}

TextInputField.defaultProps = {
  defaultValue: '',
  placeholder: '',
  required: false,
  autoComplete: 'off',
  clearable: true,
  disabled: false,
  serverErrors: {},
  modalTarget: '',
  wrapperClass: '',
  inputClass: ''
}

TextInputField.propTypes = {
  inputRef: PropTypes.object.isRequired,
  serverErrors: PropTypes.object,
  modalTarget: PropTypes.string,
  modalTargetLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  inputClass: PropTypes.string,
  wrapperClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  clearable: PropTypes.bool
}

export default memo(TextInputField)
