import 'bootstrap'
import 'styles/app.scss'

// Start Rails UJS
import './shared/jquery'
import Rails from 'rails-ujs'
Rails.start()

import 'helpers'
import 'helpers/bundles'
import 'helpers/on-modal-show'
import 'print-this'

import './static/six-payments'
import './static/print'
const importAll = (r) => r.keys().map(r)
importAll(require.context('../images/', true, /\.(gif|jpg|png|svg|ico)$/i))

import './react_basket'
import './app/prompt-reattempt-payment'
import './app/sms-confirmable'
import './app/page-elements'

import './shared/honeybadger'
import './shared/react_flash'
import './shared/add-print-this'
