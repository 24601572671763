import bundleFragment from '../graphql/bundleFragment.graphql'

export default (_, { invalidBundles }, { client }) => {
  invalidBundles.map(({ id }) => {
    const previous = client.readFragment({
      id: `Bundle:${id}`,
      fragment: bundleFragment
    })

    const data = {
      ...previous.bundle,
      enabled: false
    }
    client.writeData({ id: `Bundle:${id}`, data: data })
  })

  return null
}
