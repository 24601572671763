import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl
} from 'react-phone-number-input'
import { I18n } from 'shared'

class InputPhoneField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
    cssClass: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    defaultCountry: PropTypes.string
  }

  static defaultProps = {
    hasError: false
  }

  state = {
    hasEdited: false
  }

  render() {
    const {
      label,
      required,
      value,
      name,
      onChange,
      cssClass,
      placeholder,
      hasError,
      errorMessage,
      defaultCountry
    } = this.props
    const { hasEdited } = this.state

    const error =
      (value && !isValidPhoneNumber(value)) || (hasError && !hasEdited)

    return (
      <div className={classNames('form-group string', cssClass)}>
        {label && (
          <label className="form-control-label" htmlFor={name}>
            {label}
            {required && <abbr title={I18n.t('common.required')}>*</abbr>}
          </label>
        )}

        <PhoneInput
          defaultCountry={defaultCountry}
          className={classNames('form-control-phone', { 'is-invalid': error })}
          name={name}
          id={name}
          placeholder={placeholder}
          value={value || ''}
          onChange={(e) => {
            this.setState({ hasEdited: true })
            onChange(e === undefined ? null : formatPhoneNumberIntl(e))
          }}
        />

        {!hasEdited && hasError && errorMessage && (
          <small className="form-text text-danger">{errorMessage}</small>
        )}
      </div>
    )
  }
}

export default InputPhoneField
