import $ from 'jquery'
import Rails from 'rails-ujs'

$(function () {
  $('.js-auto-submit').on('change', function () {
    let form = $(this).closest('form')[0]
    if ($(form).data('remote')) {
      Rails.fire(form, 'submit')
    } else {
      $(form).trigger('submit')
    }
  })
})
