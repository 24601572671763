import React from 'react'
import PropTypes from 'prop-types'
import ModalContext from './ModalContext'

const ModalHeader = ({ children }) => (
  <ModalContext.Consumer>
    {(context) => (
      <div className={'modal-header'}>
        <h5 className={'modal-title'}>{children}</h5>
        <button type={'button'} className={'close'} onClick={context.onClose}>
          <i className={'fal fa-times fa-xs'} />
        </button>
      </div>
    )}
  </ModalContext.Consumer>
)

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired
}

export default ModalHeader
