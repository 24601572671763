import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { I18n } from 'shared'

class InputField extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentValue: props.value,
      hasError: this.props.hasError
    }
  }

  static defaultProps = {
    type: 'text',
    hasError: false,
    grouped: true,
    clearable: false,
    disabled: false,
    testid: ''
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    hint: PropTypes.string,
    maxLength: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
    cssClass: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    testid: PropTypes.string
  }

  handleChange = (e) => {
    const { onChange } = this.props
    this.setState({ currentValue: e.target.value, hasError: false })
    if (onChange) {
      onChange(e)
    }
  }

  handleFocus = (e) => {
    const { onFocus } = this.props
    if (onFocus) {
      onFocus(e)
    }
  }

  handleBlur = (e) => {
    const { onBlur } = this.props
    if (onBlur) {
      onBlur(e)
    }
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props
    if (oldProps.hasError !== newProps.hasError) {
      this.setState({
        hasError: newProps.hasError
      })
    }
    if (oldProps.value !== newProps.value) {
      this.setState({ currentValue: newProps.value })
    }
  }

  render() {
    const {
      label,
      hint,
      maxLength,
      required,
      name,
      onChange,
      cssClass,
      type,
      placeholder,
      disabled,
      clearable,
      errorMessage,
      testid
    } = this.props
    const { hasError, currentValue } = this.state

    return (
      <div
        className={classNames('form-group', 'string', cssClass, {
          clearable,
          'form-group-invalid': hasError
        })}
      >
        {label && (
          <label className="form-control-label" htmlFor={name}>
            {label}
            {required && <abbr title={I18n.t('common.required')}>*</abbr>}
          </label>
        )}

        <input
          data-testid={testid}
          className={classNames('form-control translated-field ', {
            required,
            'is-invalid': hasError
          })}
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          value={currentValue || ''}
          maxLength={maxLength}
          disabled={disabled}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          required={required}
        />
        {hint && <small className="form-text text-muted">{hint}</small>}
        {hasError && errorMessage && (
          <small className="form-text text-danger text-sm">
            {errorMessage}
          </small>
        )}
        {clearable && currentValue.length > 0 && (
          <i
            className={'input-clear fal fa-times fa-sm fa-fw'}
            onClick={(e) => {
              onChange(e)
            }}
          />
        )}
      </div>
    )
  }
}

export default InputField
