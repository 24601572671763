import getBasketState from '../graphql/getBasketState.graphql'

export default (_, { input }, { client }) => {
  const query = getBasketState
  const previous = client.readQuery({
    query
  })

  const data = {
    ...previous,
    ...input
  }

  client.writeData({ data: data })
  return null
}
