import React from 'react'
import PropTypes from 'prop-types'

const Loading = ({ loadingLabel = '', minHeight = 0, minWidth = 0 }) => {
  return (
    <div
      style={{
        minHeight: `${minHeight}px`,
        minWidth: `${minWidth}px`,
        display: 'block'
      }}
    >
      {loadingLabel}
    </div>
  )
}

Loading.propTypes = {
  loadingLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  minHeight: PropTypes.number,
  minWidth: PropTypes.number
}
export default Loading
