import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Alert extends Component {
  componentDidMount() {
    this.timer = setTimeout(this.props.onClose, this.props.timeout)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  alertClass(type) {
    let classes = {
      success: 'alert-success',
      error: 'alert-danger',
      alert: 'alert-warning',
      notice: 'alert-info'
    }
    return classes[type] || classes.success
  }

  alertIcon(type) {
    let classes = {
      success: 'fa-check-circle',
      error: 'fa-times-circle',
      alert: 'fa-hand-paper',
      notice: 'fa-info-circle'
    }
    return classes[type] || classes.success
  }

  render() {
    const message = this.props.message
    const alertClassName = `react-alert alert ${this.alertClass(
      message.type
    )} ${message.shake ? 'springish' : 'animated fadeInDown'}`
    const iconClassName = `fal ${this.alertIcon(message.type)}`
    return (
      <div className={alertClassName}>
        <div className="js-flash-toggle" onClick={this.props.onClose}>
          &times;
        </div>
        <div className="flash-alert-icon">
          <i className={iconClassName} />
        </div>
        <div className="flash-alert-message">{message.text}</div>
      </div>
    )
  }
}

Alert.propTypes = {
  onClose: PropTypes.func,
  timeout: PropTypes.number,
  message: PropTypes.object.isRequired
}

Alert.defaultProps = {
  timeout: 20000
}

export default Alert
