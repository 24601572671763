import React, { Component } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v1'
import Alert from './alert'
import { withStorage } from 'shared'

// Add message client side with:
// window.flash_messages.addMessage({ text: 'React, FTW!', type: 'success' });

// Add message and redirect
// window.flash_messages.redirectWithMessage({ text: 'React, FTW!', type: 'success' }, '/admin/events/1/teams/2/participations/84')

class FlashMessages extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    loadState: PropTypes.func.isRequired,
    saveState: PropTypes.func.isRequired,
    deleteState: PropTypes.func.isRequired
  }

  state = {
    messages: []
  }

  componentDidMount = () => {
    window.flash_messages = this
    const { loadState, deleteState } = this.props
    let hasFlash = loadState('flash')
    let messages = []
    if (hasFlash) {
      deleteState('flash')
      messages = [...this.props.messages, hasFlash]
    } else {
      messages = this.props.messages
    }
    this.setState({
      messages: messages
    })
  }

  addMessage(message) {
    if (this.alreadyDisplaying(message)) {
      this.shakeMessage(message)
    } else {
      this.setState({
        messages: [message]
      })
    }
  }

  redirectWithMessage = (message, location) => {
    const { saveState } = this.props
    saveState('flash', message, () => {
      window.location = location
    })
  }

  shakeMessage(message) {
    this.setState({
      messages: this.state.messages.map((_message) => {
        if (_message.text === message.text) {
          return { ..._message, shake: true }
        }
        return _message
      })
    })
  }

  removeMessage(message) {
    this.setState({
      messages: this.state.messages.filter((_message) => {
        return _message !== message
      })
    })
  }

  alreadyDisplaying(message) {
    let search = this.find(message)
    return search.length > 0
  }

  find(message) {
    return this.state.messages.filter((_message) => {
      return _message.text == message.text
    })
  }

  clearMessages() {
    this.setState({
      messages: []
    })
  }

  render() {
    const alerts = this.state.messages.map((message) => (
      <Alert
        key={uuid()}
        message={message}
        onClose={() => this.removeMessage(message)}
      />
    ))
    return <div className="react-flash-message">{alerts}</div>
  }
}

export default withStorage(FlashMessages, 'FlashMessages')
