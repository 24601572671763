import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ModalBody = ({ className, children }) => (
  <div className={classNames('modal-body', className)}>{children}</div>
)

ModalBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default ModalBody
