import React from 'react'
import PropTypes from 'prop-types'
import { I18n } from 'shared'

const OneTouchButton = ({ masked, brand, disableCheckout, beforeCheckout }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <button
        type="submit"
        className={'btn btn-light btn-block btn-lg spec-one-touch-button'}
        disabled={disableCheckout === true}
        onClick={(e) => {
          e.preventDefault()
          if (!disableCheckout) {
            beforeCheckout()
          }
        }}
      >
        {I18n.t('basket.one_touch_label')}
        <br />
        <span className="payment-card d-flex flex-row align-items-center justify-content-center">
          <span className={`mr-2 fab fa-cc-${brand}`} />
          <span className="mask font-weight-light text-sm">{masked}</span>
        </span>
      </button>
      <div className="primary-payment-notice d-flex flex-row mt-2 mb-2 align-items-center justify-content-between text-xs w-100 gray-500">
        <div className="rule border-bottom flex-grow mr-2" />
        {I18n.t('common.or')}
        <div className="rule border-bottom flex-grow ml-2" />
      </div>
    </div>
  )
}

OneTouchButton.propTypes = {
  masked: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  beforeCheckout: PropTypes.func.isRequired,
  disableCheckout: PropTypes.bool.isRequired
}

export default OneTouchButton
