import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const FormGroupInput = ({
  label,
  className,
  placeholder,
  inputValue,
  defaultValue,
  inputName,
  inputId,
  autoComplete,
  autoCorrect,
  spellCheck,
  handleChange,
  onEnter,
  onKeyDown,
  disabled,
  required
}) => {
  return (
    <label className={`text-input ${className}`}>
      {defaultValue.length > 0 ? (
        <input
          className={`field ${defaultValue.length === 0 ? 'is-empty' : ''}`}
          placeholder={placeholder}
          type="text"
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          spellCheck={spellCheck}
          defaultValue={defaultValue}
          name={inputName}
          disabled={disabled}
          id={inputId}
          onChange={(event) => {
            handleChange(event)
          }}
          onFocus={(event) => {
            event.target.classList.add('is-focused')
          }}
          onBlur={(event) => {
            event.target.classList.remove('is-focused')
          }}
          onKeyDown={(event) => {
            onKeyDown(event)
          }}
          onKeyPress={(event) => {
            if (event.key == 'Enter') {
              event.preventDefault()
              event.stopPropagation()
              onEnter()
            }
          }}
        />
      ) : (
        <input
          className={`field ${inputValue.length === 0 ? 'is-empty' : ''}`}
          placeholder={placeholder}
          type="text"
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          spellCheck={spellCheck}
          value={inputValue}
          name={inputName}
          disabled={disabled}
          id={inputId}
          onChange={(event) => {
            handleChange(event)
          }}
          onFocus={(event) => {
            event.target.classList.add('is-focused')
          }}
          onBlur={(event) => {
            event.target.classList.remove('is-focused')
          }}
          onKeyDown={(event) => {
            onKeyDown(event)
          }}
          onKeyPress={(event) => {
            if (event.key == 'Enter') {
              event.preventDefault()
              event.stopPropagation()
              onEnter()
            }
          }}
        />
      )}

      <span>
        <span
          className={classNames({
            required: required
          })}
        >
          {label}
        </span>
      </span>
    </label>
  )
}

FormGroupInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.string,
  spellCheck: PropTypes.string,
  handleBlur: PropTypes.func,
  onEnter: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  inputValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
}

FormGroupInput.defaultProps = {
  inputValue: '',
  inputName: '',
  defaultValue: '',
  autoCorrect: 'on',
  autoComplete: 'on',
  spellCheck: 'true',
  disabled: false,
  required: false,
  onEnter: () => {},
  onKeyDown: () => {}
}
export default FormGroupInput
