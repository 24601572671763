import accounting from 'accounting'
import { Buffer } from 'buffer'
import { I18n } from 'shared'
import decamelize from 'decamelize'
import { PAGE_LIMIT } from 'helpers/constants'

export const formatCurrency = (currency, amount, symbol = true) => {
  let format = currency.symbol_first || currency.symbolFirst ? '%s%v' : '%v%s'
  let isoSubUnit = currency.subunit_to_unit || currency.subunitToUnit
  const subUnitToUnit = window.isoSubUnitToUnit === true ? isoSubUnit : 100

  let precision = isoSubUnit == 1 ? 0 : 2

  if (window.isoSubUnitToUnit !== true) {
    // Override Getin's subUnit lie
    precision = 0
  }

  let options = {
    symbol: symbol == true ? currency.symbol : '',
    decimal: I18n.t('number.currency.format.separator'),
    thousand: I18n.t('number.currency.format.delimiter'),
    precision: precision,
    format: format
  }

  return accounting.formatMoney(amount / subUnitToUnit, options)
}

export function unformatCurrency(str, currency) {
  let amount = accounting.unformat(
    str,
    I18n.t('number.currency.format.separator'),
    I18n.t('number.currency.format.delimiter')
  )
  let isoSubUnit = currency.subunit_to_unit || currency.subunitToUnit
  const subUnitToUnit = window.isoSubUnitToUnit === true ? isoSubUnit : 100
  return amount * subUnitToUnit
}

export const formatDecimalCurrency = (currency, amount, symbol = true) => {
  let format = currency.symbol_first || currency.symbolFirst ? '%s%v' : '%v%s'
  let isoSubUnit = currency.subunit_to_unit || currency.subunitToUnit
  let precision = isoSubUnit == 1 ? 0 : 2

  if (window.isoSubUnitToUnit !== true) {
    // Override Getin's subUnit lie
    precision = 0
  }

  let options = {
    symbol: symbol == true ? currency.symbol : '',
    decimal: I18n.t('number.currency.format.separator'),
    thousand: I18n.t('number.currency.format.delimiter'),
    precision: precision,
    format: format
  }

  return accounting.formatMoney(amount, options)
}

export const currencyByIdCode = (currency_code, currencies) => {
  return currencies.find((currency) => currency.id_code === currency_code)
}

export const currenciesByStorage = (type, currencies) => {
  return currencies.filter(({ storage }) => {
    return storage === type
  })
}

export function debounce(a, b, c) {
  var d, e
  return function () {
    function h() {
      ;(d = null), c || (e = a.apply(f, g))
    }
    var f = this,
      g = arguments
    return (
      clearTimeout(d), (d = setTimeout(h, b)), c && !d && (e = a.apply(f, g)), e
    )
  }
}

export function slugify(string) {
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export function removeByKey(myObj, deleteKey) {
  return Object.keys(myObj)
    .filter((key) => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = myObj[current]
      return result
    }, {})
}

export function printObject(o, indent) {
  var out = ''
  if (typeof indent === 'undefined') {
    indent = 0
  }
  for (var p in o) {
    if (o.hasOwnProperty(p)) {
      var val = o[p]
      out += new Array(4 * indent + 1).join(' ') + p + ': '
      if (typeof val === 'object') {
        if (val instanceof Date) {
          out += 'Date "' + val.toISOString() + '"'
        } else {
          out +=
            '{\n' +
            printObject(val, indent + 1) +
            new Array(4 * indent + 1).join(' ') +
            '}'
        }
      } else if (typeof val === 'function') {
        null
      } else {
        out += '"' + val + '"'
      }
      out += ',\n'
    }
  }
  return out
}

export const toCamelCase = (obj) => {
  let rtn = obj
  if (typeof obj === 'object') {
    if (obj instanceof Array) {
      rtn = obj.map(toCamelCase)
    } else {
      rtn = {}
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = key.replace(/(_\w)/g, (k) => k[1].toUpperCase())
          rtn[newKey] = toCamelCase(obj[key])
        }
      }
      if (Object.keys(rtn).length === 0) {
        rtn = ''
      }
    }
  }
  return rtn
}

export const isEmptyObject = (obj) =>
  Object.keys(obj).every(
    (x) => obj[x] === '' || obj[x] === null || obj[x] === undefined
  )

export const toUnderscore = (obj) =>
  Object.fromEntries(Object.entries(obj).map(([k, v]) => [decamelize(k), v]))

export function sortCollection(objects, property) {
  let sorted = [...objects]
  sorted.sort((a, b) => (a[property] > b[property] ? 1 : -1))
  return sorted
}

export const hasError = (attr, errors) => {
  return errors && errors.includes(attr)
}

export const parseError = (error) => {
  let errors = []
  error.graphQLErrors.map(({ extensions: { problems } }) => {
    if (problems) {
      problems.map((problem) => {
        const attrs = problem.path.filter(
          (path) => typeof path == 'string' && path != '__typename'
        )
        errors = [...errors, ...attrs]
      })
    }
  })
  return errors
}

export const getPaginationWindow = (values, pageLimit = PAGE_LIMIT) => {
  const { first, last, before, after } = values
  if (first && after) {
    return {
      first: parseInt(first, 10) || pageLimit,
      after
    }
  } else if (last && before) {
    return {
      last: parseInt(last, 10) || pageLimit,
      before
    }
  } else {
    return {
      first: parseInt(first, 10) || pageLimit
    }
  }
}

const idxPrefix = 'idx---'

const fromBase64 = (encoded) => {
  return Buffer.from(encoded, 'base64').toString('utf8')
}

export const indexFromCursor = (cursor) => {
  if (cursor) {
    let index = fromBase64(cursor)
    return parseInt(index.replace(idxPrefix, ''), 10)
  }
}

export const bsClassForStatus = (status) =>
  ({
    initialized: 'light',
    balance_synced: 'info',
    gateway_refund_processed: 'warning',
    gateway_refund_pre_processed: 'danger',
    insufficient_funds: 'danger',
    confirmed: 'info',
    exported: 'success'
  }[status.toLowerCase()])
