import React from 'react'
import PropTypes from 'prop-types'

const Failed = ({ message = '', minHeight = 0, minWidth = 0 }) => {
  return (
    <div
      style={{
        minHeight: `${minHeight}px`,
        minWidth: `${minWidth}px`,
        display: 'block'
      }}
    >
      {message}
    </div>
  )
}

Failed.propTypes = {
  message: PropTypes.string,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number
}
export default Failed
