require('intl-tel-input/build/js/intlTelInput')
require('intl-tel-input/build/js/utils')

import $ from 'jquery'

$(function () {
  $('.js-new-sms-confirmation').on('submit', function () {
    var intlNumber
    intlNumber = $('#sms_confirmation_telephone_number').intlTelInput(
      'getNumber'
    )
    $('#sms_confirmation_telephone_number').val(intlNumber)
  })
  return $('.js-display_telephone').intlTelInput({
    initialCountry: 'auto',
    preferredCountries: [],
    placeholderNumberType: 'MOBILE',
    autoPlaceholder: 'aggressive'
  })
})
