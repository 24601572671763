import I18n from 'i18n-js'
import translations from 'shared/i18n/i18n.json'

const { defaultLocale, locale, eventTranslations } = window.I18n

function mergeDeep(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object'

  if (!isObject(target) || !isObject(source)) {
    return source
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key]
    const sourceValue = source[key]

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue)
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue)
    } else {
      target[key] = sourceValue
    }
  })

  return target
}

I18n.defaultLocale = defaultLocale || 'en'
I18n.locale = locale || 'en'
I18n.translations = {}

I18n.translations[I18n.locale] = mergeDeep(
  translations[locale],
  eventTranslations
)

I18n.hmn = (model, count = 1) =>
  I18n.t(model, { count: count, scope: 'activerecord.models' })

I18n.han = (model, attribute, options = {}) =>
  I18n.t(['activerecord', 'attributes', model, attribute], options)

export default I18n
