const $ = window.$

$(() => {
  $(document).on('click', '.btn-add-bundle', function () {
    if (this.dataset.bundle_id) {
      let id = parseInt(this.dataset.bundle_id)
      return window.basket.addBundle(id)
    } else {
      console.log('missing bundle_id on button')
    }
  })
})
