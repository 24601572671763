import React from 'react'
import { render } from 'react-dom'
import { attributesToCamelProps } from 'helpers/attributesToProps'
import { ApolloProvider } from '@apollo/react-hoc'
import client from 'src/basket/Client'
import App from 'src/basket/App'

document.addEventListener('DOMContentLoaded', () => {
  let root_node = document.getElementById('react_basket')
  let portal_node = document.getElementById('react_order')
  if (!root_node) {
    return false
  }
  let props = attributesToCamelProps(root_node)
  if (props.renderBasket) {
    render(
      <ApolloProvider client={client}>
        <App {...props} portalNode={portal_node} />
      </ApolloProvider>,
      root_node
    )
  }
})
