import React from 'react'
import { I18n } from 'shared'
const ValidatingOrder = () => {
  return (
    <div>
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        <div id="preloaderSpinner" className="spinner text-center text-info">
          <div className="spinWrap">
            <p className="spinnerImage"></p>
            <p className="loader">
              <span className="fa-stack">
                <i className="circle fa-stack-2x"></i>
                <i className="fal fa-circle-notch fa-stack-2x fa-faster-spin"></i>

                <i className="fal fa-lock-alt fa-stack-1x"></i>
              </span>
            </p>
          </div>
          <p className="loadingMessage mt-3" id="spinnerMessage">
            {I18n.t('basket.validating_order')}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ValidatingOrder
