import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import compose from 'lodash.flowright'
import { graphql } from '@apollo/react-hoc'
import { PayButton, OneTouchButton } from '../components'
import { I18n } from 'shared'

import {
  getBasketState,
  updateCurrentBasketState,
  validateOrder
} from '../graphql'

class Checkout extends Component {
  static propTypes = {
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total: PropTypes.number.isRequired,
    primaryPaymentMethod: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object
    ]),
    disableCheckout: PropTypes.bool.isRequired,
    oneTouchEnabled: PropTypes.bool.isRequired,
    formattedTotal: PropTypes.string.isRequired,
    updateCurrentBasketState: PropTypes.func.isRequired,
    validateOrder: PropTypes.func.isRequired,
    oneTouchUrl: PropTypes.string.isRequired,
    checkoutUrl: PropTypes.string.isRequired
  }

  handleValidation = (callback) => {
    const { updateCurrentBasketState, validateOrder, orderId } = this.props

    updateCurrentBasketState({
      variables: {
        input: {
          validating: true,
          disableCheckout: true
        }
      }
    })
    validateOrder({
      variables: {
        orderId
      }
    }).then(
      ({
        data: {
          validateOrder: { errors }
        }
      }) => {
        if (errors.length === 0) {
          window.location.href = callback
        } else {
          const duplicateOrderPermissions = errors.reduce((acc, obj) => {
            if (obj.path === 'duplicateOrderPermissions') {
              acc = [...acc, ...obj.detail]
            }
            return acc
          }, [])

          const duplicateGuestPermissions = errors.reduce((acc, obj) => {
            if (obj.path === 'duplicateGuestPermissions') {
              acc = [...acc, ...obj.detail]
            }
            return acc
          }, [])

          const excessCurrencies = errors.reduce((acc, obj) => {
            if (obj.path === 'maxBalanceHit') {
              acc = [
                ...acc,
                ...obj.detail.map((obj) => {
                  return {
                    ...obj,
                    __typename: 'ValidationError',
                    tokenCurrency: {
                      ...obj.tokenCurrency,
                      __typename: 'TokenCurrencyType'
                    }
                  }
                })
              ]
            }
            return acc
          }, [])

          updateCurrentBasketState({
            variables: {
              input: {
                validating: false,
                disableCheckout: true,
                duplicateOrderPermissions: duplicateOrderPermissions,
                duplicateGuestPermissions: duplicateGuestPermissions,
                excessCurrencies: excessCurrencies
              }
            }
          })
        }
      }
    )
  }

  render() {
    const {
      total,
      oneTouchEnabled,
      primaryPaymentMethod,
      formattedTotal,
      disableCheckout,
      oneTouchUrl,
      checkoutUrl
    } = this.props

    return (
      <div className="d-flex flex-column">
        {total > 0 ? (
          <Fragment>
            {oneTouchEnabled && primaryPaymentMethod && (
              <OneTouchButton
                {...primaryPaymentMethod}
                disableCheckout={disableCheckout}
                beforeCheckout={() => {
                  this.handleValidation(oneTouchUrl)
                }}
              />
            )}

            <PayButton
              beforeCheckout={() => {
                this.handleValidation(checkoutUrl)
              }}
              disableCheckout={disableCheckout}
              label={
                primaryPaymentMethod
                  ? I18n.t('basket.checkout_label')
                  : `${I18n.t('common.pay')} ${formattedTotal}`
              }
            />
          </Fragment>
        ) : (
          <div
            className={
              'btn btn-light btn-block btn-lg btn-disabled spec-empty-basket'
            }
          >
            {I18n.t('basket.basket_empty_label')}
          </div>
        )}
      </div>
    )
  }
}

export default compose(
  graphql(updateCurrentBasketState, { name: 'updateCurrentBasketState' }),
  graphql(validateOrder, { name: 'validateOrder' }),
  graphql(getBasketState, {
    props: ({ data: { disableCheckout } }) => ({
      disableCheckout
    })
  })
)(Checkout)
