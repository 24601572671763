import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Checkbox } from '../'
import PropTypes from 'prop-types'
import { I18n } from 'shared'

const CheckboxInput = ({
  cssClass,
  name,
  label,
  checkboxLabel,
  hasError,
  required,
  onChange,
  errorMessage,
  selected
}) => {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(hasError)
  }, [hasError])

  const handleChanged = ({ target: { checked, value: newValue } }) => {
    let result = checked ? newValue : null
    setError(false)
    return onChange({ target: { name, value: result } })
  }

  return (
    <div
      className={classNames('form-group', 'check_boxes', cssClass, {
        'form-group-invalid': error
      })}
    >
      {label && (
        <label className="form-control-label" htmlFor={name}>
          {label}
          {required && <abbr title={I18n.t('common.required')}>*</abbr>}
        </label>
      )}
      <Checkbox
        onChange={handleChanged}
        label={checkboxLabel}
        hasError={error}
        name={name}
        required={required}
        checked={selected === 'true'}
        value={'true'}
      />

      {error && errorMessage && (
        <small className="form-text text-danger text-sm">{errorMessage}</small>
      )}
    </div>
  )
}

CheckboxInput.propTypes = {
  cssClass: PropTypes.any,
  name: PropTypes.any.isRequired,
  label: PropTypes.string,
  checkboxLabel: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  selected: PropTypes.string
}

export default CheckboxInput
