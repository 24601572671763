import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { PAGE_LIMIT } from 'helpers/constants'
import { I18n } from 'shared'

const PagingNav = ({
  handleChangePagination,
  pageInfo: { startCursor, endCursor, hasPreviousPage, hasNextPage },
  pageLimit,
  totalCount
}) => {
  return (
    totalCount > 0 &&
    (hasPreviousPage || hasNextPage) && (
      <div className="row mt-3">
        <div className="col">
          <div
            className={classNames('btn btn-block btn-light', {
              disabled: !hasPreviousPage
            })}
            onClick={() => {
              if (hasPreviousPage) {
                handleChangePagination({ last: pageLimit, before: startCursor })
              }
            }}
          >
            {I18n.t('common.previous')}
          </div>
        </div>

        <div className="col">
          <div
            className={classNames('btn btn-block btn-light', {
              disabled: !hasNextPage
            })}
            onClick={() => {
              if (hasNextPage) {
                handleChangePagination({ first: pageLimit, after: endCursor })
              }
            }}
          >
            {I18n.t('common.next')}
          </div>
        </div>
      </div>
    )
  )
}

PagingNav.defaultProps = {
  pageLimit: PAGE_LIMIT
}

PagingNav.propTypes = {
  handleChangePagination: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  pageLimit: PropTypes.number,
  pageInfo: PropTypes.object
}

export default PagingNav
