import $ from 'jquery'

$(function () {
  const setState = function (input) {
    if (input.value.length === 0) {
      input.classList.add('is-empty')
    } else {
      input.classList.remove('is-empty')
      input.classList.remove('has-error')
    }
  }

  $('input.field').each(function () {
    setState(this)
  })

  $(document)
    .on('focus', 'input.field', function (e) {
      e.stopPropagation()
      $(this).addClass('is-focused')
    })
    .on('blur', 'input.field', function (e) {
      e.stopPropagation()
      $(this).removeClass('is-focused')
      setState(this)
    })
    .on('keyup', 'input.field', function (e) {
      e.stopPropagation()
      setState(this)
    })
})
