import getCurrentBasket from '../graphql/getCurrentBasket.graphql'

export default (_, { id, attr, value }, { cache }) => {
  const query = getCurrentBasket
  const previous = cache.readQuery({
    query,
    variables: { id: id }
  })

  const data = {
    currentBasket: {
      ...previous.currentBasket,
      [attr]: value
    }
  }
  cache.writeData({ id: `Order:${id}`, data: data })
  return null
}
