import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Tabs = ({
  labels,
  tabType,
  cssClass,
  tabClasses,
  activeTab,
  handleClick,
  justified
}) => {
  return (
    <ul className={classNames('nav', 'nav-tabs', cssClass)}>
      {labels.map((tab, index) => {
        return (
          <li
            key={`tab-${index}`}
            onClick={(e) => {
              e.preventDefault()
              handleClick(index)
            }}
            className={classNames('nav-item', tabClasses[index], {
              'flex-fill': justified
            })}
          >
            <a
              href="#"
              className={classNames('nav-link', {
                active: activeTab === index,
                btn: tabType === 'large',
                'rounded-0': tabType === 'large'
              })}
            >
              {tab}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

Tabs.defaultProps = {
  activeTab: 0,
  justified: false,
  tabClasses: []
}

Tabs.propTypes = {
  labels: PropTypes.array.isRequired,
  tabType: PropTypes.oneOf(['small', 'large']).isRequired,
  cssClass: PropTypes.string,
  tabClasses: PropTypes.array,
  activeTab: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  justified: PropTypes.bool
}

export default Tabs
