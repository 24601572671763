import React from 'react'
import PropTypes from 'prop-types'

const PayButton = ({ label, disableCheckout, beforeCheckout }) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault()
        if (!disableCheckout) {
          beforeCheckout()
        }
      }}
      className={'btn btn-primary btn-block btn-lg spec-checkout-button h-100'}
      disabled={disableCheckout === true}
    >
      {label}
    </button>
  )
}

PayButton.propTypes = {
  label: PropTypes.string.isRequired,
  beforeCheckout: PropTypes.func.isRequired,
  disableCheckout: PropTypes.bool.isRequired
}
export default PayButton
