import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import classNames from 'classnames'

import { I18n } from 'shared'

export default class SelectOptField extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
      PropTypes.object
    ]),
    required: PropTypes.bool,
    cssClass: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onInputChange: PropTypes.func,
    hasError: PropTypes.bool,
    isMulti: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    placeholder: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    isClearable: PropTypes.bool
  }

  static defaultProps = {
    hasError: false,
    isMulti: false,
    isLoading: false,
    isClearable: true,
    closeMenuOnSelect: true,
    onInputChange: () => {}
  }

  state = {
    hasEdited: false
  }

  getValue = (val) => {
    const { options } = this.props
    return [].concat(
      ...options.map(({ options }) => {
        return options.filter((option) => {
          return val instanceof Array
            ? val.includes(option.value)
            : option.value === val
        })
      })
    )
  }

  render() {
    const {
      label,
      required,
      value,
      name,
      options,
      onChange,
      onInputChange,
      cssClass,
      hasError,
      placeholder,
      noOptionsMessage,
      isMulti,
      isLoading,
      isClearable,
      closeMenuOnSelect
    } = this.props
    const { hasEdited } = this.state

    return (
      <div className={classNames('form-group', cssClass)}>
        {label && (
          <label className="form-control-label" htmlFor={name}>
            {label}
            {required && <abbr title={I18n.t('common.required')}>*</abbr>}
          </label>
        )}
        <Select
          isLoading={isLoading}
          name={name}
          instanceId={name}
          value={this.getValue(value) || false}
          options={options}
          placeholder={placeholder}
          onChange={(e, meta) => {
            this.setState({ hasEdited: true })
            if (meta.action === 'clear') {
              onChange({ value: '' })
            } else {
              onChange(e)
            }
          }}
          onInputChange={onInputChange}
          className={classNames({ 'is-invalid': hasError && !hasEdited })}
          isMulti={isMulti}
          isClearable={isClearable}
          hideSelectedOptions={true}
          openMenuOnFocus={true}
          noOptionsMessage={() => {
            return noOptionsMessage || I18n.t('common.no_options_available')
          }}
          closeMenuOnSelect={closeMenuOnSelect}
          classNamePrefix="react-select"
          styles={{ option: () => ({}) }}
        />
      </div>
    )
  }
}
