import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tabs, ArrayInput } from 'shared'
import classNames from 'classnames'

const TranslatedArrayInput = ({
  label,
  locales,
  attrName,
  className,
  translations,
  handleChange
}) => {
  const [currentLocale, setCurrentLocale] = useState(locales[0])
  const [currentTab, setCurrentTab] = useState(0)

  useEffect(() => {
    setCurrentLocale(locales[currentTab])
  }, [locales, currentTab])

  const inputNameFor = (locale) => {
    return attrName.replace(/LOCALE/, locale)
  }

  return (
    <div>
      <div className="w-100 d-flex justify-content-between align-items-end">
        {label && <label className="text-sm gray-500">{label}</label>}
        <Tabs
          tabType="small"
          labels={locales.map((locale) => locale.toUpperCase())}
          cssClass="d-flex justify-content-end mb-0 border-0"
          handleClick={(index) => setCurrentTab(index)}
          activeTab={currentTab}
        />
      </div>

      <div className={`row ${className}`}>
        {locales.map((locale) => {
          return (
            <Fragment key={`input_${attrName}_${locale}`}>
              <ArrayInput
                handleChange={(e) => {
                  handleChange(e, locale)
                }}
                field={inputNameFor(locale)}
                current={translations[locale] || []}
                placeholder={''}
                cssClass={classNames(
                  `form-group col-12 mt-0 pt-0 ${className}`,
                  {
                    show: locale === currentLocale,
                    'd-none': locale !== currentLocale,
                    'is-empty': translations[locale]
                      ? translations[locale].length === 0
                      : true
                  }
                )}
              />
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

TranslatedArrayInput.propTypes = {
  label: PropTypes.string,
  locales: PropTypes.array.isRequired,
  translations: PropTypes.object,
  attrName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string
}
export default TranslatedArrayInput
