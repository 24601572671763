import React from 'react'
import PropTypes from 'prop-types'
import { OrderItem, OrderAccount } from '../components'
import { I18n } from 'shared'

const OrderView = ({
  userId,
  currentBasket,
  orderItems,
  formattedTotal,
  editable,
  renderKeepBrowsing,
  keepBrowsingUrl,
  authenticityToken,
  connectedAccountsEnabled
}) => {
  return (
    <div className="order-panel">
      <div className="order-body w-100">
        <div className="flex-column">
          <div className="order-items border-bottom mb-3 pb-3">
            {orderItems.map((orderItem, index) => {
              return (
                <OrderItem
                  key={`order_item-${index}`}
                  {...orderItem}
                  editable={editable}
                  orderId={currentBasket.id}
                />
              )
            })}
          </div>

          <div className="d-flex flex-row align-items-center justify-content-between border-bottom mb-3 pb-3">
            <strong>{I18n.t('basket.total_label')}:</strong>
            <strong className="basket-total">{formattedTotal}</strong>
          </div>

          {connectedAccountsEnabled && (
            <OrderAccount
              currentBasket={currentBasket}
              userId={userId}
              keepBrowsingUrl={keepBrowsingUrl}
            />
          )}

          <form
            action={keepBrowsingUrl}
            method="get"
            className="d-flex flex-row align-items-center justify-content-end"
          >
            <input
              type="hidden"
              name="authenticity_token"
              value={authenticityToken}
            />
            {renderKeepBrowsing && (
              <button className={'btn btn-success btn-block btn-lg'}>
                {I18n.t('basket.keep_browsing_label')}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

OrderView.propTypes = {
  userId: PropTypes.number.isRequired,
  orderItems: PropTypes.array,
  formattedTotal: PropTypes.string,
  currentBasket: PropTypes.object.isRequired,
  keepBrowsingUrl: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  renderKeepBrowsing: PropTypes.bool,
  authenticityToken: PropTypes.string.isRequired,
  connectedAccountsEnabled: PropTypes.bool.isRequired
}

export default OrderView
