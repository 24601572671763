import React from 'react'
import PropTypes from 'prop-types'
import compose from 'lodash.flowright'
import { graphql } from '@apollo/react-hoc'
import { I18n } from 'shared'

import {
  ValidatingOrder,
  ValidationErrors,
  OrderItem,
  ConnectedAccount,
  Checkout
} from '../components'

import { getBasketState } from '../graphql'

const Basket = ({
  open,
  userId,
  editable,
  total,
  formattedTotal,
  validating,
  orderItems,
  setBasketOpen,
  oneTouchEnabled,
  connectedAccountsEnabled,
  disableCheckout,
  primaryPaymentMethod,
  currentBasket,
  excessCurrencies,
  duplicateOrderPermissions,
  wristbandLabel,
  oneTouchUrl,
  checkoutUrl
}) => {
  return (
    <div className="basket-panel">
      <div
        onClick={() => setBasketOpen(!open)}
        className={`d-flex flex-row align-items-center justify-content-between btn btn-icon-left basket-toggle ${
          total > 0 ? 'btn-success' : 'btn-default btn-disabled'
        }`}
      >
        <span className="fal fa-shopping-basket mr-2" />
        <div className="basket-total text-right flex-grow">
          {formattedTotal}
        </div>
      </div>
      {open && (
        <div className="basket-body card w-100">
          <div className="card-body flex-column">
            <h5 className="card-title d-flex flex-row align-items-center mb-4">
              <span className="fal fa-lock-alt mr-2" />
              <span className="flex-grow">{I18n.t('basket.basket_label')}</span>
              <span
                onClick={() => setBasketOpen(false)}
                className="fal fa-times-circle basket-close"
              />
            </h5>
            {validating ? (
              <ValidatingOrder />
            ) : (
              <div className="order-items border-bottom mb-3 pb-3">
                {orderItems.map((orderItem, index) => {
                  return (
                    <OrderItem
                      key={`order_item-${index}`}
                      {...orderItem}
                      editable={editable}
                      orderId={currentBasket.id}
                    />
                  )
                })}
              </div>
            )}

            <div className="d-flex flex-row align-items-center justify-content-between border-bottom mb-3 pb-3">
              <strong>{I18n.t('basket.total_label')}:</strong>
              <strong className="basket-total">{formattedTotal}</strong>
            </div>

            {disableCheckout && !validating && (
              <ValidationErrors
                duplicateOrderPermissions={duplicateOrderPermissions}
                excessCurrencies={excessCurrencies}
                wristbandLabel={wristbandLabel}
              />
            )}

            {connectedAccountsEnabled && (
              <ConnectedAccount currentBasket={currentBasket} userId={userId} />
            )}

            <Checkout
              userId={userId}
              orderId={currentBasket.id}
              total={total}
              oneTouchEnabled={oneTouchEnabled}
              primaryPaymentMethod={primaryPaymentMethod}
              formattedTotal={formattedTotal}
              disableCheckout={disableCheckout}
              oneTouchUrl={oneTouchUrl}
              checkoutUrl={checkoutUrl}
            />
          </div>
          <div className="arrow-up" />
        </div>
      )}
    </div>
  )
}

Basket.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.number.isRequired,
  formattedTotal: PropTypes.string.isRequired,
  currentBasket: PropTypes.object.isRequired,
  orderItems: PropTypes.array,
  validating: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  oneTouchEnabled: PropTypes.bool.isRequired,
  connectedAccountsEnabled: PropTypes.bool.isRequired,
  disableCheckout: PropTypes.bool.isRequired,
  primaryPaymentMethod: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  setBasketOpen: PropTypes.func.isRequired,
  excessCurrencies: PropTypes.array,
  duplicateOrderPermissions: PropTypes.array,
  wristbandLabel: PropTypes.string,
  oneTouchUrl: PropTypes.string.isRequired,
  checkoutUrl: PropTypes.string.isRequired
}

export default compose(
  graphql(getBasketState, {
    props: ({
      data: {
        open,
        disableCheckout,
        validating,
        duplicateOrderPermissions,
        excessCurrencies
      }
    }) => ({
      open,
      disableCheckout,
      validating,
      duplicateOrderPermissions,
      excessCurrencies
    })
  })
)(Basket)
