import $ from 'jquery'
import 'bootstrap'

$(function () {
  if ($('.prompt-reattempt-payment')) {
    $('.prompt-reattempt-payment').modal({
      backdrop: 'static',
      keyboard: false,
      show: true
    })
  }
})
