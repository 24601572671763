import React from 'react'
import PropTypes from 'prop-types'
import { I18n } from 'shared'
const OrderAccount = ({ currentBasket, keepBrowsingUrl }) => {
  const { connectedAccount } = currentBasket
  return (
    <div className="mb-3 border notice rounded py-3 px-2">
      <div className="d-flex justify-content-between align-items-center">
        {connectedAccount ? (
          <span>
            {I18n.t('connected_accounts.send_to', {
              nickname: connectedAccount.nickname
            })}
          </span>
        ) : (
          <span>{I18n.t('connected_accounts.send_to_you')}</span>
        )}
        <a className="text-sm" href={`${keepBrowsingUrl}`}>
          {I18n.t('common.change')}
        </a>
      </div>
    </div>
  )
}

OrderAccount.propTypes = {
  currentBasket: PropTypes.object.isRequired,
  keepBrowsingUrl: PropTypes.string.isRequired
}
export default OrderAccount
