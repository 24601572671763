import $ from 'jquery'

$(function () {
  const setCheckboxes = function (collection, checked) {
    $.each(collection, function (_index, checkbox) {
      if (checked) {
        checkbox.setAttribute('checked', true)
      } else {
        checkbox.removeAttribute('checked')
      }
    })
  }
  $('.js-check-all').on('click', function () {
    let $el = $(this)
    let target = $el.data('target')
    let collection = []
    if ($(target).hasClass('shuffle')) {
      collection = $(target).find('.shuffle-item--visible input[type=checkbox]')
    } else {
      collection = $(target).find('input[type=checkbox]')
    }
    setCheckboxes(collection, true)
  })
  $('.js-uncheck-all').on('click', function () {
    let $el = $(this)
    let target = $el.data('target')
    let collection = []
    if ($(target).hasClass('shuffle')) {
      collection = $(target).find('.shuffle-item--visible input[type=checkbox]')
    } else {
      collection = $(target).find('input[type=checkbox]')
    }
    setCheckboxes(collection, false)
  })
})
