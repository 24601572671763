import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ModalContext from './ModalContext'
import { I18n } from 'shared/index'

const ModalFooter = ({
  className,
  closeLabel,
  saveLabel,
  busyLabel,
  btnClass,
  hideActionBtn
}) => (
  <ModalContext.Consumer>
    {({ onClose, handleCommit, busy, disabled }) => (
      <div
        className={classNames(
          'modal-footer',
          'd-flex flex-row',
          'justify-content-end',
          'align-items-center',
          className
        )}
      >
        <button
          type="button"
          className="btn btn-light mr-2"
          data-dismiss="modal"
          onClick={onClose}
        >
          {closeLabel}
        </button>

        {!hideActionBtn && (
          <button
            type={'button'}
            disabled={disabled}
            className={classNames('btn', btnClass, 'spec-commit', {
              disabled: busy || disabled
            })}
            onClick={!busy && !disabled ? handleCommit : undefined}
          >
            {busy ? busyLabel : saveLabel}
          </button>
        )}
      </div>
    )}
  </ModalContext.Consumer>
)

ModalFooter.defaultProps = {
  closeLabel: I18n.t('common.close'),
  saveLabel: I18n.t('common.save'),
  busyLabel: I18n.t('common.saving'),
  hideActionBtn: false,
  btnClass: 'btn-primary'
}

ModalFooter.propTypes = {
  className: PropTypes.string,
  closeLabel: PropTypes.string,
  saveLabel: PropTypes.string,
  busyLabel: PropTypes.string,
  btnClass: PropTypes.string,
  hideActionBtn: PropTypes.bool
}

export default ModalFooter
