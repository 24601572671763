import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import compose from 'lodash.flowright'
import { graphql } from '@apollo/react-hoc'
import { formatCurrency } from 'src/helpers/utils'
import { Loading } from 'shared'
import { Basket, OrderView } from './components'

import {
  updateCurrentBasketState,
  getCurrentBasket,
  getOrder,
  orderAddBundle,
  getBasketState
} from './graphql'

class App extends Component {
  constructor(props) {
    super(props)
    window.basket = this
  }

  static propTypes = {
    loading: PropTypes.bool,
    wristbandLabel: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    orderId: PropTypes.number.isRequired,
    orderStatus: PropTypes.string.isRequired,
    renderOrder: PropTypes.bool.isRequired,
    portalNode: PropTypes.object,
    currentBasket: PropTypes.object,
    orderAddBundle: PropTypes.func.isRequired,
    primaryPaymentMethod: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object
    ]),
    oneTouchEnabled: PropTypes.bool.isRequired,
    connectedAccountsEnabled: PropTypes.bool.isRequired,
    updateCurrentBasketState: PropTypes.func.isRequired,
    duplicateOrderPermissions: PropTypes.array.isRequired,
    duplicateGuestPermissions: PropTypes.array.isRequired,
    authenticityToken: PropTypes.string.isRequired,
    oneTouchUrl: PropTypes.string.isRequired,
    checkoutUrl: PropTypes.string.isRequired,
    keepBrowsingUrl: PropTypes.string.isRequired,
    renderKeepBrowsing: PropTypes.bool.isRequired
  }

  setBasketOpen = (open) => {
    const { updateCurrentBasketState } = this.props

    updateCurrentBasketState({
      variables: {
        input: {
          open
        }
      }
    })
  }

  lineItemsFrom = (bundles) => {
    const {
      currentBasket: { baseCurrency, user },
      duplicateOrderPermissions,
      duplicateGuestPermissions
    } = this.props

    const userIdentiferTypes = user.identifiers.map(
      ({ identifierType }) => identifierType.id
    )

    const aggregated = bundles.reduce((obj, bundle) => {
      let quantity = bundles.filter(({ id }) => id === bundle.id).length
      let calculatedPriced = quantity * bundle.priceInCents
      let formattedCalculatedPrice = formatCurrency(
        baseCurrency,
        calculatedPriced
      )
      obj[bundle.id] = {
        ...bundle,
        quantity,
        calculatedPriced,
        formattedCalculatedPrice,
        orderPermissionExists: duplicateOrderPermissions.includes(bundle.id),
        guestPermissionExists: duplicateGuestPermissions.includes(bundle.id),
        enabledForUser: this.enabledForUserIdentifierTypes(
          bundle,
          userIdentiferTypes
        )
      }
      return obj
    }, {})
    return Object.values(aggregated).sort((a, b) =>
      a.enabled === true && b.enabled !== true ? 1 : -1
    )
  }

  enabledForUserIdentifierTypes = (bundle, userIdentiferTypes) => {
    const bundleIdentiferTypes = bundle.identifierTypes.map(
      (identifierType) => identifierType.id
    )
    return (
      bundleIdentiferTypes.length === 0 ||
      bundleIdentiferTypes.filter((x) => userIdentiferTypes.includes(x))
        .length > 0
    )
  }

  addBundle = (bundleId) => {
    const {
      orderAddBundle,
      updateCurrentBasketState,
      currentBasket: { id }
    } = this.props

    orderAddBundle({
      variables: {
        orderId: id,
        bundleId: bundleId
      }
    }).then(() => {
      updateCurrentBasketState({
        variables: {
          input: {
            disableCheckout: false
          }
        }
      })
    })
  }

  aggregatedTotal = (orderItems) => {
    return orderItems.reduce(
      (
        acc,
        { enabled, enabledForUser, guestPermissionExists, calculatedPriced }
      ) => {
        if (
          enabled === true &&
          enabledForUser === true &&
          guestPermissionExists !== true
        ) {
          return acc + calculatedPriced
        } else {
          return acc
        }
      },
      0
    )
  }

  render() {
    const {
      loading,
      currentBasket,
      renderOrder,
      portalNode,
      renderKeepBrowsing,
      primaryPaymentMethod,
      oneTouchEnabled,
      connectedAccountsEnabled,
      authenticityToken,
      userId,
      wristbandLabel,
      oneTouchUrl,
      checkoutUrl,
      keepBrowsingUrl
    } = this.props

    if (loading || !currentBasket) return <Loading />

    const { baseCurrency, bundles } = currentBasket
    const orderItems = this.lineItemsFrom(bundles)
    const aggregatedTotal = this.aggregatedTotal(orderItems)
    const formattedTotal = formatCurrency(baseCurrency, aggregatedTotal)

    return (
      <div className="basket mr-md-4">
        {renderOrder && portalNode ? (
          createPortal(
            <OrderView
              userId={userId}
              currentBasket={currentBasket}
              formattedTotal={formattedTotal}
              total={aggregatedTotal}
              orderItems={orderItems}
              keepBrowsingUrl={keepBrowsingUrl}
              renderKeepBrowsing={renderKeepBrowsing}
              connectedAccountsEnabled={connectedAccountsEnabled}
              authenticityToken={authenticityToken}
              editable={!renderOrder}
            />,
            portalNode
          )
        ) : (
          <Basket
            userId={userId}
            editable={!renderOrder}
            currentBasket={currentBasket}
            formattedTotal={formattedTotal}
            total={aggregatedTotal}
            orderItems={orderItems}
            setBasketOpen={this.setBasketOpen}
            primaryPaymentMethod={primaryPaymentMethod}
            oneTouchEnabled={oneTouchEnabled}
            connectedAccountsEnabled={connectedAccountsEnabled}
            wristbandLabel={wristbandLabel.toLowerCase()}
            oneTouchUrl={oneTouchUrl}
            checkoutUrl={checkoutUrl}
          />
        )}
      </div>
    )
  }
}

export default compose(
  graphql(getBasketState, {
    props: ({
      data: { duplicateGuestPermissions, duplicateOrderPermissions }
    }) => ({
      duplicateGuestPermissions,
      duplicateOrderPermissions
    })
  }),
  graphql(orderAddBundle, { name: 'orderAddBundle' }),
  graphql(updateCurrentBasketState, { name: 'updateCurrentBasketState' }),
  graphql(getCurrentBasket, {
    props: ({ data: { currentBasket, loading, error } }) => ({
      currentBasket,
      loading,
      error
    }),
    skip: (props) => props.orderStatus == 'payment_pending',
    options: ({ userId }) => ({
      variables: {
        userId
      }
    })
  }),
  graphql(getOrder, {
    props: ({ data: { order, loading, error } }) => ({
      currentBasket: order,
      loading,
      error
    }),
    skip: (props) => props.orderStatus != 'payment_pending',
    options: ({ orderId }) => ({
      variables: {
        orderId
      }
    })
  })
)(App)
