import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'

// Use the FragmentMatcher to teach ApolloClient about our Interfaces and Unions
// When you add a new Union / Interface and want to use it with apollo you
// should also update this file.
// @see https://www.apollographql.com/docs/react/advanced/fragments.html#fragment-matcher
// @see https://github.com/apollographql/react-apollo/issues/756
export const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'CurrencyInterface',
          possibleTypes: [{ name: 'RealCurrency' }, { name: 'TokenCurrency' }]
        }
      ]
    }
  }
})
