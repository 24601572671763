import React, { Component } from 'react'
import { debounce } from 'helpers/utils'

const withStorage = (WrappedComponent, namespace = 'Crew') => {
  class HOC extends Component {
    namespacedKey = (key) => {
      return `Crew:${namespace}:${key}`
    }

    saveState = debounce((key, state, callback) => {
      try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem(this.namespacedKey(key), serializedState)
      } catch (_err) {
        // Ignore write errors.
      }
      if (callback) {
        callback()
      }
    }, 500)

    loadState = (key) => {
      try {
        const serializedState = localStorage.getItem(this.namespacedKey(key))
        if (serializedState === null) {
          return undefined
        }
        return JSON.parse(serializedState)
      } catch (err) {
        return undefined
      }
    }

    deleteState = (key) => {
      try {
        localStorage.removeItem(this.namespacedKey(key))
      } catch (err) {
        return undefined
      }
    }
    render() {
      return (
        <WrappedComponent
          loadState={this.loadState}
          saveState={this.saveState}
          deleteState={this.deleteState}
          {...this.props}
        />
      )
    }
  }

  return HOC
}

export default withStorage
