import React from 'react'
import PropTypes from 'prop-types'
import { indexFromCursor } from 'helpers/utils'
import { I18n } from 'shared'

const PageInfo = ({ startCursor, endCursor, totalCount, cssClass }) => {
  const startIndex = indexFromCursor(startCursor)
  const endIndex = indexFromCursor(endCursor)

  return (
    <div className={cssClass}>
      {startIndex && endIndex ? (
        <small>
          {I18n.t('helpers.page_info.paged', {
            start: startIndex,
            end: endIndex,
            total: totalCount
          })}
        </small>
      ) : (
        <small>
          {I18n.t('helpers.page_info.total', { total: totalCount })}
        </small>
      )}
    </div>
  )
}

PageInfo.propTypes = {
  startCursor: PropTypes.string,
  endCursor: PropTypes.string,
  totalCount: PropTypes.number,
  cssClass: PropTypes.string
}

export default PageInfo
