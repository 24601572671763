import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ label, checked, inputName, handleChange, cssClass }) => {
  return (
    <div className={`switch ${cssClass}`}>
      <label className="d-flex flex-row align-items-center justify-content-between">
        <span className="switch-label mr-3">{label}</span>
        <input type="hidden" name={inputName} value={`${checked}`} />
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => handleChange(e)}
          name={inputName}
          className="cb-switch cb-switch-round-flat"
        />
        <span className="switch-bg" />
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  inputName: PropTypes.string.isRequired,
  cssClass: PropTypes.string
}

export default Checkbox
