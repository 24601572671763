import $ from 'jquery'
import 'bootstrap'

$(() => {
  window.PageElements &&
    window.PageElements.map(
      ({ enabled, targets: { route, position, action }, content }) => {
        if (enabled) {
          let $target = $(`body.${route}`).find(position)
          if ($target) {
            let fn = $target[action]
            if (typeof fn === 'function') {
              fn.call($target, content)
            }
          }
        }
      }
    )
})
