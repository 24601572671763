import React from 'react'

const ModalContext = React.createContext({
  busy: false,
  disabled: false,
  handleCommit: () => {},
  onClose: () => {}
})

export default ModalContext
