import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import compose from 'lodash.flowright'
import { graphql } from '@apollo/react-hoc'
import {
  getConnectedAccounts,
  updateCurrentBasketConnectedAccount,
  updateCurrentBasketState
} from '../graphql'

import { I18n, Loading } from 'shared'

class TargetAccount extends Component {
  constructor(props) {
    super(props)
    window.connectedAccounts = this
  }

  static propTypes = {
    loading: PropTypes.bool,
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentBasket: PropTypes.object.isRequired,
    connectedAccounts: PropTypes.array,
    refetchConnectedAccounts: PropTypes.func.isRequired,
    updateCurrentBasketConnectedAccount: PropTypes.func.isRequired,
    updateCurrentBasketState: PropTypes.func.isRequired
  }

  refresh = () => {
    const { refetchConnectedAccounts } = this.props
    refetchConnectedAccounts()
  }

  backToMine = () => {
    this.updateTargetAccount(null)
      .then(() => {
        this.refresh()
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  updateTargetAccount = (connectedAccountId) => {
    const { updateCurrentBasketConnectedAccount, userId } = this.props

    return updateCurrentBasketConnectedAccount({
      variables: {
        input: {
          userId,
          connectedAccountId
        }
      }
    })
      .then(() => {
        this.enableCheckout()
      })
      .catch((error) => {
        console.warn(error)
      })
  }

  enableCheckout = () => {
    const { updateCurrentBasketState } = this.props
    updateCurrentBasketState({
      variables: {
        input: {
          validating: false,
          disableCheckout: false,
          duplicateGuestPermissions: []
        }
      }
    })
  }

  render() {
    const {
      loading,
      connectedAccounts,
      currentBasket: { connectedAccountId }
    } = this.props
    if (loading || !connectedAccounts) return <Loading />

    return (
      <Fragment>
        {connectedAccounts.length > 0 && (
          <div className="border-bottom pb-3 mb-3 mt-n2 d-flex flex-row align-items-center justify-content-between">
            <div className="flex-grow">
              <label className="target-account-label label gray-700 text-sm">
                {I18n.t('connected_accounts.target_label')}
              </label>
              <select
                className="target-account-select form-control form-control-select"
                value={connectedAccountId || ''}
                onChange={(e) => {
                  this.updateTargetAccount(e.target.value)
                }}
              >
                <option value={null}>{I18n.t('connected_accounts.you')}</option>
                <option disabled>{'──────────'}</option>
                {connectedAccounts.map(
                  ({ id, nickname, identifierNumber, enabled }) =>
                    enabled && (
                      <option key={identifierNumber} value={id}>
                        {I18n.t('connected_accounts.target', {
                          nickname: nickname
                        })}
                      </option>
                    )
                )}
              </select>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}

export default compose(
  graphql(getConnectedAccounts, {
    props: ({ data: { connectedAccounts, loading, error, refetch } }) => ({
      connectedAccounts,
      loading,
      error,
      refetchConnectedAccounts: refetch
    }),
    options: ({ userId }) => ({
      variables: {
        userId
      }
    })
  }),
  graphql(updateCurrentBasketState, { name: 'updateCurrentBasketState' }),
  graphql(updateCurrentBasketConnectedAccount, {
    name: 'updateCurrentBasketConnectedAccount'
  })
)(TargetAccount)
