import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class TranslatedFieldInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentLocale: props.locales[0]
    }
  }

  static propTypes = {
    locales: PropTypes.array.isRequired,
    attrName: PropTypes.string.isRequired,
    translations: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    inputClass: PropTypes.string,
    className: PropTypes.string
  }

  handleLocaleChange = (locale) => {
    this.setState({
      currentLocale: locale
    })
  }

  inputNameFor = (locale) => {
    let { attrName } = this.props
    return attrName.replace(/LOCALE/, locale)
  }

  render() {
    const { currentLocale } = this.state
    const {
      locales,
      attrName,
      handleChange,
      translations,
      label,
      inputClass,
      className
    } = this.props
    return (
      <label className={`text-input d-flex ${className}`}>
        {locales.map((locale) => {
          return (
            <Fragment key={`input_${attrName}_${locale}`}>
              <input
                type="text"
                defaultValue={translations[locale]}
                name={`${this.inputNameFor(locale)}`}
                className={classNames(`field ${inputClass}`, {
                  show: locale === currentLocale,
                  'd-none': locale !== currentLocale,
                  'is-empty': translations[locale]
                    ? translations[locale].length === 0
                    : true
                })}
                onChange={(e) => {
                  handleChange(e, locale)
                }}
                onFocus={(e) => {
                  e.target.classList.add('is-focused')
                }}
                onBlur={(e) => {
                  e.target.classList.remove('is-focused')
                }}
              />
              {label && (
                <span
                  className={classNames({
                    show: locale === currentLocale,
                    'd-none': locale !== currentLocale
                  })}
                >
                  <span>{label}</span>
                </span>
              )}
            </Fragment>
          )
        })}

        <div className="input-group-append">
          <button
            className="btn btn-default dropdown-toggle gray-600"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {currentLocale}
          </button>
          <div className="dropdown-menu">
            {locales.map((locale) => {
              return (
                <a
                  className="dropdown-item"
                  href="#"
                  key={`dropdown_${attrName}_${locale}`}
                  onClick={(e) => {
                    e.preventDefault()
                    this.handleLocaleChange(locale)
                  }}
                >
                  {locale.toUpperCase()}
                </a>
              )
            })}
          </div>
        </div>
      </label>
    )
  }
}
