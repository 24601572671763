import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { I18n } from 'shared'

const RadioGroupInput = ({
  options,
  cssClass,
  label,
  name,
  selected,
  isDisabled,
  hasError,
  errorMessage,
  required,
  onChange
}) => {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(hasError)
  }, [hasError])

  return (
    <div className={classNames('form-group', cssClass)}>
      {label && (
        <label className="form-control-label mr-2" htmlFor={name}>
          {label}
          {required && <abbr title={I18n.t('common.required')}>*</abbr>}
        </label>
      )}

      <input type={'hidden'} name={name} value={selected || ''} />
      <div
        className={classNames(
          'btn-group',
          'radio-group',
          'd-flex flex-row',
          'align-items-center',
          { 'is-invalid': error }
        )}
      >
        {options.map(({ label, value }) => (
          <button
            key={`${name}-${value}`}
            type="button"
            className={classNames(
              'btn btn-block',
              'btn-outline-secondary',
              'mt-0',
              { selected: value === selected, disabled: isDisabled }
            )}
            onClick={() => {
              if (isDisabled) return
              setError(false)
              onChange(value)
            }}
          >
            {label}
          </button>
        ))}
      </div>
      {error && errorMessage && (
        <small className="form-text text-danger text-sm">{errorMessage}</small>
      )}
    </div>
  )
}

RadioGroupInput.defaultProps = {
  isDisabled: false
}

RadioGroupInput.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  cssClass: PropTypes.string
}

export default RadioGroupInput
