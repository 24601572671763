import $ from 'jquery'

$(function () {
  $(document).ready(function () {
    var $iconLink = $("link[rel~='icon']")
    var faviconUrl =
      $iconLink.attr('href') || window.location.origin + '/favicon.ico'
    var img = document.createElement('img')
    img.crossOrigin = 'anonymous'

    var onFaviconLoaded = function () {
      var canvas = document.createElement('canvas')
      canvas.width = 128
      canvas.height = 128

      var context = canvas.getContext('2d')
      context.drawImage(img, 0, 0)
      context.globalCompositeOperation = 'source-in'

      var env = $iconLink.data('environment')
      var colorHash = {
        edge: '#d9534f',
        staging: '#5cb85c',
        development: '#f0ad4e',
        translate: '#ff0099',
        production: '#518eeb'
      }

      context.fillStyle = colorHash[env] || '#000'
      context.fillRect(0, 0, 128, 128)
      context.fill()
      $iconLink.attr('type', 'image/x-icon')
      $iconLink.attr('href', canvas.toDataURL())
    }

    img.addEventListener('load', onFaviconLoaded)
    img.src = faviconUrl
  })
})
