import React from 'react'
import PropTypes from 'prop-types'
import compose from 'lodash.flowright'
import { graphql } from '@apollo/react-hoc'
import { orderRemoveBundle, updateCurrentBasketState } from '../graphql'
import { I18n } from 'shared'

const OrderItem = ({
  id,
  enabled,
  enabledForUser,
  editable,
  nameText,
  formattedCalculatedPrice,
  quantity,
  availableNow,
  guestPermissionExists,
  orderId,
  orderRemoveBundle,
  updateCurrentBasketState
}) => {
  const disabled = !(
    enabled &&
    enabledForUser &&
    availableNow &&
    !guestPermissionExists
  )
  return (
    <div className={`basket-item mb-1 pb-1 ${disabled ? 'disabled' : ''}`}>
      <div className="d-flex flex-row align-items-center justify-content-between">
        {editable ? (
          <span
            className="fal fa-minus-circle remove-basket-item mr-2"
            onClick={() => {
              orderRemoveBundle({
                variables: {
                  orderId: orderId,
                  bundleId: id
                }
              }).then(() => {
                updateCurrentBasketState({
                  variables: {
                    input: {
                      disableCheckout: false,
                      excessCurrencies: []
                    }
                  }
                })
              })
            }}
          />
        ) : null}
        <div className="basket-item-quantity mr-2">{`${quantity} x `}</div>
        <div className="basket-item-name flex-grow">
          {nameText && nameText.trim()}
        </div>
        <div className="basket-item-price">{formattedCalculatedPrice}</div>
      </div>
      {disabled && (
        <div className="d-flex flex-row ml-4 text-sm">
          {guestPermissionExists ? (
            <span className="spec-duplicate-guest-permission text-sm">
              {I18n.t('basket.validation_issues.contains_existing_permission')}
            </span>
          ) : (
            <span className="text-sm">
              {I18n.t('basket.disabled_bundle_label')}
            </span>
          )}
        </div>
      )}
    </div>
  )
}

OrderItem.propTypes = {
  id: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  nameText: PropTypes.string,
  formattedCalculatedPrice: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  availableNow: PropTypes.bool,
  enabled: PropTypes.bool,
  enabledForUser: PropTypes.bool,
  guestPermissionExists: PropTypes.bool,
  orderRemoveBundle: PropTypes.func.isRequired,
  updateCurrentBasketState: PropTypes.func.isRequired
}

export default compose(
  graphql(orderRemoveBundle, { name: 'orderRemoveBundle' }),
  graphql(updateCurrentBasketState, { name: 'updateCurrentBasketState' })
)(OrderItem)
