import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { I18n } from 'shared'

import { MaxBalance } from '../components'

const ValidationErrors = ({
  excessCurrencies,
  duplicateOrderPermissions,
  wristbandLabel
}) => {
  const hasError =
    [...excessCurrencies, ...duplicateOrderPermissions].length > 0

  return hasError ? (
    <div className="mb-3 border border-danger notice rounded py-3 px-2">
      {duplicateOrderPermissions.length > 0 && (
        <p
          className="text mb-0 spec-duplicate-order-permission"
          dangerouslySetInnerHTML={{
            __html: I18n.t(
              'basket.validation_issues.duplicate_order_permissions_html',
              {
                permission_name: duplicateOrderPermissions[0]
              }
            )
          }}
        ></p>
      )}
      {excessCurrencies.length > 0 && (
        <MaxBalance
          label={I18n.t('basket.validation_issues.max_balance', {
            wristband_label: wristbandLabel
          })}
          excessCurrencies={excessCurrencies}
        />
      )}
    </div>
  ) : (
    <Fragment />
  )
}

ValidationErrors.propTypes = {
  wristbandLabel: PropTypes.string.isRequired,
  excessCurrencies: PropTypes.array,
  duplicateOrderPermissions: PropTypes.array
}

export default ValidationErrors
