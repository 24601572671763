import React from 'react'
import { render } from 'react-dom'
import FlashMessages from 'shared/flash/messages'
import attributesToProps from 'helpers/attributesToProps'

document.addEventListener('DOMContentLoaded', () => {
  const root_node = document.getElementById('react_flash')
  if (!root_node) {
    return false
  }
  let props = attributesToProps(root_node)
  render(<FlashMessages {...props} />, root_node)
})
