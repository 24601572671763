import { toCamelCase } from 'helpers/utils'

export const attributesToCamelProps = (root_node) => {
  return attributesToProps(root_node, true)
}

const attributesToProps = (root_node, camelize = false) => {
  let props = Object.keys(root_node.dataset).reduce((object, key) => {
    try {
      // TODO: revisit attributesToCamelProps usage everywhere
      // Without this logic, "4545" would be converted to an Integer while
      // the rest of the react application expects the field to be a String
      // and thus errors out (notably in addon voucher / link identifier functionality)
      if (['receivingIdentifierNumber', 'voucherNumber'].includes(key)) {
        object[key] = root_node.dataset[key]
      } else {
        object[key] = JSON.parse(root_node.dataset[key])
      }
    } catch (err) {
      object[key] = root_node.dataset[key]
      if (['true', true, 1, 'yes', 'on'].includes(root_node.dataset[key])) {
        object[key] = true
      }
      if (['false', false, 0, 'no', 'off'].includes(root_node.dataset[key])) {
        object[key] = false
      }
    }
    return object
  }, {})
  if (camelize) {
    return toCamelCase(props)
  } else {
    return props
  }
}

export default attributesToProps
