import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ToggleButton = ({ label, cssClass, iconClass, active, handleChange }) => {
  return (
    <button
      className={classNames('btn', 'btn-outline-secondary', cssClass, {
        active
      })}
      onClick={handleChange}
      type={'button'}
    >
      {iconClass ? <i className={iconClass} /> : label}
    </button>
  )
}

ToggleButton.propTypes = {
  label: PropTypes.string,
  iconClass: PropTypes.string,
  cssClass: PropTypes.string,
  active: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
}

export default ToggleButton
