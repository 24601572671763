import { ApolloLink, Observable } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { BatchHttpLink } from 'apollo-link-batch-http'
import Honeybadger from 'honeybadger-js'
import { RetryLink } from 'apollo-link-retry'
import { I18n } from 'shared'

export const retryLink = new RetryLink()

export const httpLink = new BatchHttpLink({
  uri: '/api/graphql',
  credentials: 'same-origin'
})

export const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle
      Promise.resolve(operation)
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

export const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    console.warn(networkError)
    Honeybadger.notify(networkError)

    if (
      [400, 401, 404, 406, 422, 429, 500].includes(networkError?.statusCode)
    ) {
      window.flash_messages.addMessage({
        text: I18n.t(`pages.errors.${networkError?.statusCode}_json`),
        type: 'error'
      })
    }
  } else if (graphQLErrors) {
    console.error(graphQLErrors)
    Honeybadger.notify(graphQLErrors)
    let errors = []

    graphQLErrors.map(({ extensions, message }) => {
      if (extensions && extensions.problems) {
        extensions.problems.map(({ explanation }) => {
          errors.push(explanation)
        })
      } else if (message) {
        errors.push(message)
      }
    })
    window.flash_messages.addMessage({
      text: errors.join('\n'),
      type: 'error'
    })
  }
})
