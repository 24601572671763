import $ from 'jquery'

$(function () {
  $('[data-toggle="popover"]').popover({
    trigger: 'hover',
    html: true,
    container: 'body',
    boundary: 'viewport'
  })
})
