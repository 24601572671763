import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Checkbox = ({
  name,
  label,
  value,
  checked,
  onChange,
  hasError,
  labelClass,
  inputClass
}) => {
  const handleChange = () => {
    onChange({
      target: {
        name,
        value,
        checked: !checked
      }
    })
  }

  return (
    <div className={'custom-control custom-checkbox'}>
      <input
        id={`${name}_${value}`}
        className={classNames(
          `custom-control-input check_boxes ${inputClass}`,
          {
            'is-invalid': hasError
          }
        )}
        type={'checkbox'}
        checked={checked}
        value={value}
        name={name}
        onChange={handleChange}
      />

      <label
        className={classNames(
          `custom-control-label collection_check_boxes ${labelClass}`
        )}
        htmlFor={`${name}_${value}`}
      >
        {label}
      </label>
    </div>
  )
}

Checkbox.defaultProps = {
  checked: false,
  labelClass: '',
  inputClass: ''
}

Checkbox.propTypes = {
  hasError: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]).isRequired
}

export default Checkbox
