import React from 'react'
import PropTypes from 'prop-types'

const NoneFoundNotice = ({ message, notice, cssClass }) => {
  return (
    <div className={`rounded border notice border-${notice} ${cssClass}`}>
      <div className="text">{message}</div>
    </div>
  )
}

NoneFoundNotice.defaultProps = {
  notice: 'warning',
  cssClass: 'p-3 my-3'
}

NoneFoundNotice.propTypes = {
  message: PropTypes.string.isRequired,
  notice: PropTypes.string,
  cssClass: PropTypes.string
}

export default NoneFoundNotice
