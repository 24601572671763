import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { motion, AnimatePresence } from 'framer-motion'
import classNames from 'classnames'
import ModalContext from './ModalContext'
import ModalHeader from './ModalHeader'
import ModalFooter from './ModalFooter'
import ModalBody from './ModalBody'

const Modal = ({
  open,
  busy,
  onClose,
  size,
  id,
  children,
  handleCommit,
  disabled
}) => {
  if (!open) return null
  const modalDom = document.getElementById('react-modal')
  return createPortal(
    <ModalContext.Provider value={{ onClose, handleCommit, busy, disabled }}>
      <AnimatePresence>
        {open && (
          <motion.div
            key={'modal'}
            className={'account-modal-wrap modal show'}
            initial={{ opacity: 0, display: 'block' }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className={'account-modal'} id={id}>
              <div className={classNames('modal-dialog', size)}>
                <div className={'modal-content'}>{children}</div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {open && (
          <motion.div
            key={'modal'}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className={'modal-backdrop'}
          />
        )}
      </AnimatePresence>
    </ModalContext.Provider>,
    modalDom
  )
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  handleCommit: PropTypes.func,
  busy: PropTypes.bool,
  disabled: PropTypes.bool
}

Modal.defaultProps = {
  busy: false,
  disabled: false,
  open: false
}

Modal.Header = ModalHeader
Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
