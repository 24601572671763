import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Checkbox from './Checkbox'
import { I18n } from 'shared'

const CheckboxGroupInput = ({
  name,
  label,
  value = [],
  required,
  cssClass,
  onChange,
  hasError,
  errorMessage,
  options,
  columns,
  hint,
  selected
}) => {
  const [error, setError] = useState(false)
  const checkboxOptions = useMemo(() => {
    return options.map((obj) => ({
      label: obj[0] || obj.label,
      value: obj[1] || obj.value
    }))
  }, [options])
  useEffect(() => {
    setError(hasError)
  }, [hasError])

  const handleChange = ({ target: { checked, value: newValue } }) => {
    const cachedValue = Array.isArray(value) ? [...value] : []
    const newArrayValue = Array.isArray(newValue) ? [...newValue] : [newValue]
    let result = null

    if (checked) {
      //if selected
      result = [...cachedValue, ...newArrayValue]
    } else {
      //if deselected
      result = cachedValue.filter((item) => !newArrayValue.includes(item))
    }
    setError(false)

    return onChange({ target: { name, value: result } })
  }

  return (
    <div
      className={classNames('form-group check_boxes', cssClass, {
        'form-group-invalid': error
      })}
    >
      <input type={'hidden'} name={name} />
      {label && (
        <label className="form-control-label" htmlFor={name}>
          {label}
          {required && <abbr title={I18n.t('common.required')}>*</abbr>}
        </label>
      )}
      <div className={'checkboxes'} style={{ columns: columns }}>
        {checkboxOptions.map(({ label, value }) => {
          const checked = Array.isArray(value)
            ? value.every((v) => selected.includes(v))
            : selected.includes(`${value}`)

          return (
            <Checkbox
              key={`checkbox_${label}_${value}`}
              name={name}
              value={value}
              checked={checked}
              label={label}
              hasError={error}
              onChange={handleChange}
            />
          )
        })}
      </div>
      {hint && <small className="form-text text-muted">{hint}</small>}
      {error && errorMessage && (
        <small className="form-text text-danger text-sm">{errorMessage}</small>
      )}
    </div>
  )
}

CheckboxGroupInput.propTypes = {
  cssClass: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.any.isRequired,
  required: PropTypes.bool,
  value: PropTypes.array,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array,
  hint: PropTypes.string,
  columns: PropTypes.number.isRequired
}

CheckboxGroupInput.defaultProps = {
  required: false,
  selected: [],
  columns: 1
}

export default CheckboxGroupInput
