import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory'
import { onErrorLink, requestLink, httpLink, retryLink } from 'config/links'
import { fragmentMatcher } from 'config/fragmentMatcher'

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      default:
        return defaultDataIdFromObject(object)
    }
  },
  freezeResults: true
})

export const setupClient = (defaults = {}) => {
  const { defaultState = { isConnnected: true }, resolvers = {} } = defaults
  const client = new ApolloClient({
    link: ApolloLink.from([onErrorLink, requestLink, retryLink, httpLink]),
    resolvers: resolvers,
    cache: cache,
    assumeImmutableResults: true
  })
  client.writeData({ data: defaultState })
  return client
}
