import { setupClient } from 'src/config/apolloClient'
import { updateCurrentBasketState, disableInvalidBundles } from './resolvers'

const defaultState = {
  open: false,
  validating: false,
  disableCheckout: false,
  duplicateOrderPermissions: [],
  duplicateGuestPermissions: [],
  excessCurrencies: [],
  currentBasket: {
    id: null,
    __typename: 'OrderType',
    amount: 0,
    identifierTypes: [],
    baseCurrency: {
      __typename: 'CurrencyType',
      idCode: null,
      isoCode: null,
      subunitToUnit: 100,
      symbolFirst: true
    },
    bundles: []
  }
}

export default setupClient({
  defaultState,
  resolvers: {
    Mutation: {
      updateCurrentBasketState,
      disableInvalidBundles
    }
  }
})
