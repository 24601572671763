import React from 'react'
import PropTypes from 'prop-types'
import { formatCurrency } from 'helpers/utils'

const MaxBalance = ({ label, excessCurrencies }) => {
  return (
    <div className="spec-max-balance">
      <p className="text">{label}</p>
      <div className="pt-2 border-top pb-0">
        {excessCurrencies.map(({ tokenCurrency, excess }) => {
          return (
            <div
              className="excess-balance d-flex flex-row align-items-center justify-content-end text-sm"
              key={tokenCurrency.idCode}
            >
              <div className="excess-balance-amount mr-1">
                {formatCurrency(tokenCurrency, excess)}
              </div>
              <div className="excess-balance-currency text-uppercase">
                ({tokenCurrency.idCode})
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

MaxBalance.propTypes = {
  label: PropTypes.string.isRequired,
  excessCurrencies: PropTypes.array
}

export default MaxBalance
