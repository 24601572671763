import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'
import { I18n } from 'shared'

const components = {
  DropdownIndicator: null
}

const createOption = (label) => ({
  label,
  value: label
})

export default class ArrayInput extends Component {
  static propTypes = {
    current: PropTypes.array,
    field: PropTypes.string.isRequired,
    errors: PropTypes.string,
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    isMulti: PropTypes.bool,
    cssClass: PropTypes.string,
    label: PropTypes.string,
    isUnique: PropTypes.bool
  }

  static defaultProps = {
    handleChange: () => {},
    isMulti: true,
    isUnique: false
  }

  state = {
    inputValue: '',
    value: []
  }

  componentDidMount = () => {
    const value = this.props.current.map((opt) => createOption(opt))
    this.setState({ value: value, inputValue: '' })
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props
    if (oldProps.current !== newProps.current) {
      const value = newProps.current.map((opt) => createOption(opt))
      this.setState({ value: value, inputValue: '' })
    }
  }

  handleChange = (value) => {
    this.setState({ value }, () => {
      this.props.handleChange(
        this.state.value
          ? this.state.value.map(({ value }) => {
              return value
            })
          : []
      )
    })
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue })
  }

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state
    if (!inputValue) return
    let newOption = createOption(inputValue)
    if (!newOption.value.replace(/\s/g, '').length) return

    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (
          this.props.isUnique &&
          value.map((value) => value.value).includes(inputValue)
        )
          return
        this.setState(
          {
            inputValue: '',
            value: [...value, newOption]
          },
          () => {
            this.props.handleChange(
              this.state.value.map(({ value }) => {
                return value
              })
            )
          }
        )
        event.preventDefault()
    }
  }
  render() {
    const { inputValue, value } = this.state
    const { field, placeholder, isMulti, cssClass, label } = this.props
    return (
      <div className={cssClass}>
        {label && (
          <label className="form-control-label" htmlFor={field}>
            {label}
          </label>
        )}
        <CreatableSelect
          classNamePrefix="react-select"
          name={field}
          instanceId={field}
          components={components}
          isClearable
          isMulti={isMulti}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder || I18n.t('common.array_input')}
          value={value}
          inputValue={inputValue}
          styles={{ option: () => ({}) }}
          noOptionsMessage={() => {
            return I18n.t('common.array_input')
          }}
          isValidNewOption={() => {
            // we're showing no options message, don't make them click
            return false
          }}
        />
      </div>
    )
  }
}
