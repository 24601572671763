import $ from 'jquery'

$(function () {
  $('*[data-remote="true"]')
    .on('ajax:success', function (event) {
      let [response] = event.detail
      if ($.isArray(response)) {
        response.map(function (message) {
          window.flash_messages.addMessage({
            text: message.text,
            type: message.type
          })
        })
      }
    })
    .on('ajax:error', function (event) {
      let [_data, status, xhr] = event.detail
      window.flash_messages.addMessage({
        text: 'Server Error: (' + xhr.status + ') ' + status,
        type: 'error'
      })
    })

  $(document).on('click', '.remote-link', function () {
    if (!$(this).hasClass('open')) {
      var link = $(this).data('href')
      $.ajax({
        type: 'GET',
        url: link
      })
    }
  })
})
