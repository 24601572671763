import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const SkeletonLine = ({
  translucent = false,
  width = '100%',
  height = '100%'
}) => {
  return (
    <div
      style={{ width: width, height: height }}
      className={classNames('skeleton-pulse', {
        translucent: translucent
      })}
    >
      <div className="skeleton-line" />
    </div>
  )
}

SkeletonLine.propTypes = {
  translucent: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string
}
export default SkeletonLine
